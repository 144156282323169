import axios from "@/http/axios";

const getDefaultState = () => {
  return {
    details: undefined,
    benchmarks: undefined,
    products: undefined,
    cumulativeReturns: undefined,
    performanceData: undefined,
    drawdowns: undefined,
    biggestDrawdowns: undefined,
  };
};

const state = getDefaultState();

const getters = {
  getDetails: (state) => {
    return state.details;
  },
  getBenchmarks: (state) => {
    return state.benchmarks;
  },
  getProducts: (state) => {
    return state.products;
  },
  getCumulativeReturns: (state) => {
    return state.cumulativeReturns;
  },
  getPerformanceData: (state) => {
    return state.performanceData;
  },
  getDrawdowns: (state) => {
    return state.drawdowns;
  },
  getBiggestDrawdowns: (state) => {
    return state.biggestDrawdowns;
  },
};

const mutations = {
  FETCH_DETAILS_SUCCESS: (state, { data }) => {
    state.details = data;
  },
  FETCH_BENCHMARKS_SUCCESS: (state, { data }) => {
    state.benchmarks = data;
  },
  FETCH_PRODUCTS_SUCCESS: (state, { data }) => {
    state.products = data;
  },
  FETCH_CUMULATIVE_RETURNS_SUCCESS: (state, { data }) => {
    state.cumulativeReturns = data;
  },
  FETCH_PERFORMANCE_DATA_SUCCESS: (state, { data }) => {
    state.performanceData = data;
  },
  FETCH_DRAWDOWNS_SUCCESS: (state, { data }) => {
    state.drawdowns = data;
  },
  FETCH_BIGGEST_DRAWDOWNS_SUCCESS: (state, { data }) => {
    state.biggestDrawdowns = data;
  },
  RESET_ALL_STATES(state) {
    // Reset all the data fetched from the backend.
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async fetchDetails({ commit }) {
    try {
      const response = await axios.get(`/api/custom/details`);
      commit("FETCH_DETAILS_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_DETAILS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchBenchmarks({ commit }) {
    try {
      const response = await axios.get(`/api/custom/benchmarks`);
      commit("FETCH_BENCHMARKS_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_BENCHMARKS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchProducts({ commit }) {
    try {
      const response = await axios.get(`/api/custom/products`);
      commit("FETCH_PRODUCTS_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_PRODUCTS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchCumulativeReturns(
    { commit },
    { period, benchmark, productsWeights, withResample }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (benchmark !== undefined) {
      params["benchmark_symbol"] = benchmark;
    }
    if (productsWeights !== undefined) {
      params["products_weights"] = productsWeights;
    }
    if (withResample !== undefined) {
      params["with_resample"] = withResample;
    }
    try {
      const response = await axios.get(`/api/custom/cumulative-returns`, {
        params: params,
      });
      commit("FETCH_CUMULATIVE_RETURNS_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_CUMULATIVE_RETURNS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchPerformanceData(
    { commit },
    { period, benchmark, productsWeights, periods, statIds }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (benchmark !== undefined) {
      params["benchmark_symbol"] = benchmark;
    }
    if (productsWeights !== undefined) {
      params["products_weights"] = productsWeights;
    }
    if (periods !== undefined) {
      params["periods"] = periods;
    } else {
      params["periods"] = ["max"];
    }
    if (statIds !== undefined) {
      params["stat_ids"] = statIds;
    }
    try {
      const response = await axios.get(`/api/custom/performance-data`, {
        params: params,
      });
      commit("FETCH_PERFORMANCE_DATA_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_PERFORMANCE_DATA_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchDrawdowns({ commit }, { period, benchmark, productsWeights }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (benchmark !== undefined) {
      params["benchmark_symbol"] = benchmark;
    }
    if (productsWeights !== undefined) {
      params["products_weights"] = productsWeights;
    }
    try {
      const response = await axios.get(`/api/custom/drawdowns`, {
        params: params,
      });
      commit("FETCH_DRAWDOWNS_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_DRAWDOWNS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchBiggestDrawdowns(
    { commit },
    { period, benchmark, productsWeights }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (benchmark !== undefined) {
      params["benchmark_symbol"] = benchmark;
    }
    if (productsWeights !== undefined) {
      params["products_weights"] = productsWeights;
    }
    try {
      const response = await axios.get(`/api/custom/biggest-drawdowns`, {
        params: params,
      });
      commit("FETCH_BIGGEST_DRAWDOWNS_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_BIGGEST_DRAWDOWNS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  resetAllStates({ commit }) {
    commit("RESET_ALL_STATES");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
